import qrcodeImg from '../assets/home/qrcode-full.png'
import './log-envent.js'
import Toast from './toast'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { DASHBOARD_LIST, HTTP_STATUS, MAX_REQ_TIMEOUTS, STORAGE_KEY, WHITE_LIST } from './consts'
import beforePage from './before-page'

dayjs.extend(utc)
dayjs.extend(customParseFormat)

const isLocal = location.hostname.includes('localhost') || location.hostname.includes('192.168')
const DEV_BASE_URL = isLocal ? process.env.BASE_URL : '/'

// hide console.log in production and test
if (process.env.ENV === 'prod' || process.env.ENV === 'test') {
  console.log = function () {}
}

const baseRequest = async (url, data) => {
  return await fetch(`${DEV_BASE_URL}${url}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: data ? JSON.stringify(data) : null
  })
}

const isInDashboard = DASHBOARD_LIST.some(path => location.pathname.includes(path))
const isInWhiteList =
  WHITE_LIST.includes(location.pathname) || location.pathname.startsWith('/articles/')
const redirect = () => {
  localStorage.removeItem(STORAGE_KEY.LOGIN_TIME)
  if (isInWhiteList) {
    return
  }
  window.location.href = '/login.html'
}

const showHttpStatus = async response => {
  let res = { code: 0, message: '' }
  const contentType = response.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    res = await response.clone().json()
  }

  const status = HTTP_STATUS.find(
    status =>
      status.code === response.status || (response.status === 200 && res?.code === status.code)
  )
  if (status) {
    new Toast().toast('warning', status.message, false, {
      delay: 5000,
      callback: () => {
        if (status.code === 403 && location.pathname.includes('/models.html')) {
          window.location.href = '/landing.html'
          return true
        }

        const redirectUrl = sessionStorage.getItem(STORAGE_KEY.REDIRECT_URL)
        if (redirectUrl) {
          window.location.href = redirectUrl
          sessionStorage.removeItem(STORAGE_KEY.REDIRECT_URL)
        }
      }
    })

    return true
  }

  const isCode401 = response.status === 401 || res?.code === 401
  const isLogin = Boolean(localStorage.getItem(STORAGE_KEY.LOGIN_TIME))
  const isModelShared = isModelKey()

  const isHomeAuthed = isCode401 && isInWhiteList && isLogin
  const isOtherAuthed = isCode401 && !isInWhiteList
  const refreshToken = localStorage.getItem('SCANAT_REFRESH_TOKEN')
  // 401: Unauthorized
  if (isHomeAuthed || isOtherAuthed) {
    const tokenRes = await baseRequest('auth/refresh_token', {
      refreshToken,
      isFromPortal: true
    })

    const curTokenRes = await tokenRes.json()
    const msg =
      tokenRes.status !== 200 || curTokenRes?.code !== 200
        ? 'セキュリティ上の理由で、Scanat Webでは複数端末からの同時ログインはできません。アカウントが自動的にログアウトされました。再度ログインしてください。'
        : 'セッションの有効期限が切れました。再度ログインしてください。'

    if (!isModelShared) {
      if (tokenRes.status === 400 || curTokenRes?.code === 400) {
        redirect()
        return true
      }

      new Toast().toast('warning', msg, false, {
        callback: () => {
          redirect()
        }
      })
    }

    return true
  }

  return false
}

const request = async (
  url,
  data = {},
  isNeedToken = true,
  method = 'POST',
  isNeedResponse = false
) => {
  // check network status
  if (!navigator.onLine) {
    new Toast().toast('warning', 'ネットワーク接続を確認してください。', false, {
      delay: 5000
    })
    return
  }

  const headers = {
    'Content-Type': 'application/json'
  }

  if (isNeedToken) {
    const token = localStorage.getItem('SCANAT_TOKEN')
    headers['Authorization'] = `Bearer ${token}`
  }

  const options = {
    method,
    headers,
    mode: 'cors'
  }

  if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
    options.body = JSON.stringify(data)
  }

  let fetchUrl = `${DEV_BASE_URL}${url}`
  if (method === 'GET' && data) {
    fetchUrl = `${DEV_BASE_URL}${url}?${new URLSearchParams(data).toString()}`
  }
  if (url.startsWith('http') || url.startsWith('https')) {
    fetchUrl = url
  }

  const fetchSignal = AbortController ? new AbortController() : null
  if (fetchSignal) {
    options.signal = fetchSignal?.signal
  }

  const timeoutKey = Object.keys(MAX_REQ_TIMEOUTS).find(key => url.includes(key)) || 'default'
  const timeout = setTimeout(() => fetchSignal?.abort(), MAX_REQ_TIMEOUTS[timeoutKey])
  try {
    const response = await fetch(fetchUrl, options)
    clearTimeout(timeout)

    if (await showHttpStatus(response)) {
      return
    }

    if (isNeedResponse) {
      return response
    }

    const res = await response.json()
    if (res?.code === 781 && location.pathname.includes('/payment.html')) {
      new Toast().toast('warning', res.message, false, {
        callback: () => {
          window.location.href = `/enterprise.html`
        }
      })
      return res
    }

    if (res?.code !== 200) {
      console.error(res.message)
    }

    return res
  } catch (err) {
    console.error(err)
    if (err.name === 'AbortError') {
      new Toast().toast(
        'warning',
        '接続がタイムアウトしました。ネットワーク接続を確認するか、しばらくしてから再試行してください。',
        false,
        {
          delay: 5000
        }
      )
    }
  }
}

const formatDateTime = (dateTime, template = 'YYYY-MM-DD HH:mm') => {
  if (!dateTime) {
    return '—'
  }

  return dayjs.utc(dateTime).local().format(template)
}

// Format money: 1000000 => 1,000,000
const formatMoney = money => {
  if (!money) return 0
  return '￥' + money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const isMobile = () => window.innerWidth < 768

const formatEmailDisplay = email => {
  //  add new line every 26 characters
  const characters = email.split('')
  for (let i = 26; i < characters.length; i += 26) {
    characters[i] = characters[i] + '\n'
  }

  return characters.join('')
}

const disablePaginationItem = className => {
  const el = document.querySelector(`.${className}`)
  el.firstChild.setAttribute('disabled', '')
  el.classList.add('opacity-40')
  el.firstChild.style.pointerEvents = 'none'
}

const genPagination = (data, newData, loadList) => {
  const { total, pages } = data
  // generate pagination
  const paginationEl = document.querySelector('.pagination')
  const totalEl = document.querySelector('.pagination-total')
  totalEl.textContent = `総計 ${total} 件`

  if (pages >= 1) {
    paginationEl.innerHTML =
      '<li class="page-item page-first"><a class="page-link" href="#">最初のページ</a></li>' +
      '<li class="page-item page-divider" style="color:#00365b33">|</li>' +
      '<li class="page-item page-prev"><a class="page-link" href="#">前へ</a></li>'

    for (let i = 1; i <= pages; i++) {
      const liEl = document.createElement('li')
      liEl.classList.add('page-item', 'page-number')

      if (i === Number(newData.page)) {
        liEl.classList.add('active')
      }

      // add gap
      if (i !== pages) {
        liEl.style.marginRight = '0.5rem'
      }
      liEl.style.backgroundColor = '#0068B014'

      const aEl = document.createElement('a')
      aEl.classList.add('page-link')
      aEl.style.color = '#0068B099'

      aEl.href = '#'
      aEl.textContent = i
      liEl.appendChild(aEl)
      paginationEl.appendChild(liEl)
    }
    paginationEl.innerHTML +=
      '<li class="page-item page-next"><a class="page-link" href="#">次へ</a></li>' +
      '<li class="page-item page-divider" style="color:#00365b33">|</li>' +
      '<li class="page-item page-last"><a class="page-link" href="#">最終ページ</a></li>'

    if (pages === 1) {
      const classes = ['page-prev', 'page-next', 'page-first', 'page-last']
      classes.forEach(className => {
        disablePaginationItem(className)
      })
    }

    // only show 5 pages mostly
    // format page item to 1,2,...,length-1,length
    if (pages > 5) {
      const cursor = Number(newData.page)
      let validPages = [cursor, cursor + 1, cursor + 2, cursor + 3, cursor + 4, cursor + 5]
      let centerPage = cursor + 4
      if (cursor > pages - 5) {
        validPages = [pages - 5, pages - 4, pages - 3, pages - 2, pages - 1, pages]
        centerPage = pages - 6
      }

      const pageItems = document.querySelectorAll('.page-number')
      const pageItemsArr = Array.from(pageItems)
      pageItemsArr.forEach((item, index) => {
        if (!validPages.includes(index + 1)) {
          item.style.display = 'none'
        }

        if (index === centerPage) {
          item.querySelector('a').textContent = '…'
          item.querySelector('a').style.paddingLeft = '9px'
          item.querySelector('a').style.paddingRight = '9px'
          item.firstChild.setAttribute('disabled', '')
          item.firstChild.style.pointerEvents = 'none'
        }
      })
    }

    if (newData.page === 1) {
      disablePaginationItem('page-prev')
      disablePaginationItem('page-first')
    }

    if (newData.page === pages) {
      disablePaginationItem('page-next')
      disablePaginationItem('page-last')
    }

    paginationEl.onclick = e => {
      e.preventDefault()

      if (e.target.tagName === 'A') {
        const classes = e.target.parentElement.classList
        let currentPage = Number(e.target.textContent)

        if (classes.contains('page-first')) {
          currentPage = 1
        }

        if (classes.contains('page-prev')) {
          currentPage = newData.page
          currentPage--

          if (currentPage < 1) {
            currentPage = 1
          }
        }

        if (classes.contains('page-next')) {
          currentPage = newData.page
          currentPage++

          if (currentPage > pages) {
            currentPage = pages
          }
        }

        if (classes.contains('page-last')) {
          currentPage = pages
        }

        loadList({
          ...newData,
          page: currentPage
        })
      }
    }

    const jumpPageEl = document.querySelector('.pagination-jump')
    if (jumpPageEl) {
      jumpPageEl.value = newData.page
      jumpPageEl.onchange = e => {
        let jumpPage = e.target.value
        if (jumpPage > pages) {
          jumpPage = pages
        }

        if (jumpPage < 1) {
          jumpPage = 1
        }

        loadList({
          ...newData,
          page: jumpPage
        })
      }
    }
  }
}

const hasWhiteSpace = s => /\s/g.test(s)

const isEmail = email => /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/.test(email)

const queryAWSAccessHeader = (method, param, json) => {
  const payload = {
    method: method,
    host: 'https://image.scanat.app',
    path: process.env.ENV === 'prod' ? '/prod/image' : '/dev/image',
    payload: json
  }

  if (param) {
    payload.path = payload.path + '?' + 'key' + '=' + param
  }

  return request('aws/access/header', payload)
}

const queryDownloadURL = (queryString, key) => {
  const host = process.env.ENV === 'prod' ? 'https://image.scanat.app' : 'https://image.scanat.app'
  let path = process.env.ENV === 'prod' ? '/prod/image' : '/dev/image'
  path = path + '?' + 'key' + '=' + key + '&' + queryString

  return host + path
}

const isModelKey = () => {
  const searchValue = location.search.split('?')
  // model key is 36 characters,like 0049a868-31bc-44cd-923b-2e820920050b
  // get model key from search value use regex
  // ignore other search value like &cn&autoload
  const modelKey = searchValue.find(value => value.match(/.{8}-.{4}-.{4}-.{4}-.{12}/))
  if (!modelKey) {
    return false
  }

  return modelKey
}

const checkAccountActive = () => {
  const userInfo = JSON.parse(sessionStorage.getItem('SCANAT_USER_INFO')) ?? {}
  return [1, 4, 7].includes(userInfo?.userStatus)
}

const toDataURL = url =>
  fetch(url)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        })
    )

const showNoPermissionModal = () => {
  const noPermissionModalEl = document.querySelector('#noPermissionModal')
  if (noPermissionModalEl) {
    new mdb.Modal(noPermissionModalEl).show()
  }
}

window.isScanatLoaded = false
const handleDomContentLoad = async () => {
  window.isScanatLoaded = true
  // We need get user info first
  let res = { data: null }

  const isNoAuthPage =
    location.pathname.includes('login.html') ||
    location.pathname.includes('intage.html') ||
    location.search.includes('&intage')

  // do not need to get user info on login page
  if (!isNoAuthPage) {
    const authRes = await request('auth/currentUserInfo', null, true, 'POST', true)
    if (authRes?.status === 200) {
      res = await authRes.json()
    } else {
      console.error('error on get user info', authRes)
      document.querySelector('.login-area')?.classList.remove('d-none')
      document.querySelector('.avatar-area')?.classList.add('d-none')
      sessionStorage.removeItem('SCANAT_USER_INFO')
    }
  }

  // set avatar
  const customerNameEl = document.querySelector('.customer-name')
  const customerIdEl = document.querySelector('.customer-id')
  if (customerNameEl && customerIdEl) {
    const loginArea = document.querySelector('.login-area')

    try {
      const avatarArea = document.querySelector('.avatar-area')
      if (res?.code === 200 && res?.data) {
        // save user info
        sessionStorage.setItem('SCANAT_USER_INFO', JSON.stringify(res.data))
        if (res.data?.surname && res.data?.name) {
          customerNameEl.textContent = `${res.data.surname} ${res.data.name}`
        } else {
          customerNameEl.textContent = '名前空白'
        }

        customerIdEl.textContent = formatEmailDisplay(res.data.email)
        avatarArea?.classList.remove('d-none')
        loginArea?.classList.add('d-none')

        console.log('set avatar')
      }
    } catch (error) {
      console.error('error on set avatar', error)
    }
  }

  const navWebEl = document.querySelector('.navbar-web')
  const navDashboardEl = document.querySelector('.navbar-dashboard')
  if (navWebEl) {
    navWebEl.onclick = () => {
      if (!checkAccountActive()) {
        new Toast().toast('warning', 'アカウントが無効のため、Scanat Webを利用できません。')
        return
      }

      if (!res.data?.webPermission) {
        showNoPermissionModal()
        return
      }

      window.location.href = '/models.html'
    }
  }

  if (navDashboardEl) {
    navDashboardEl.onclick = () => {
      if (res.data?.isPortalAdmin === 0 && !!res.data?.customerId) {
        new Toast().toast('danger', '管理者権限がありません。')
        return
      }

      window.location.href = '/dashboard.html'
    }
  }

  if (location.pathname.includes('models.html')) {
    if (navWebEl) {
      navWebEl.classList.add('active')
      navWebEl.onclick = () => {}
    }
  }

  //  logout
  const logoutBtn = document.querySelector('.btn-logout')
  logoutBtn?.addEventListener('click', e => {
    e.preventDefault()
    localStorage.removeItem('SCANAT_TOKEN')
    localStorage.removeItem('SCANAT_REFRESH_TOKEN')
    localStorage.removeItem(STORAGE_KEY.LOGIN_TIME)
    window.location.href = '/login.html'
  })

  const key = isModelKey()
  if (key) {
    if (!location.pathname.includes('models.html')) {
      location.href = `/models.html?${key}`
      console.log('redirect to models')
    }
    return
  }

  const dashboardBody = document.querySelector('.dashboard-body')
  const msg =
    'この機能はまだ利用できませんが、現在リリースの準備をしており、少しお待たせしてしまうことになります。'

  if (location.pathname.includes('landing.html')) {
    const iconDashboardEl = document.querySelector('.landing-dashboard')
    if (iconDashboardEl) {
      iconDashboardEl.onclick = () => {
        if (res.data?.isPortalAdmin === 0 && !!res.data?.customerId) {
          new Toast().toast('danger', '管理者権限がありません。')
          return
        }

        window.open('/dashboard.html', '_blank')
      }
    }

    const icon = document.querySelector('.landing-models')
    // show no permission modal
    if (!res.data?.webPermission || !checkAccountActive()) {
      if (icon) {
        icon.onclick = () => {
          if (!checkAccountActive()) {
            new Toast().toast('warning', 'アカウントが無効のため、Scanat Webを利用できません。')
            return
          }

          showNoPermissionModal()
        }
      } else {
        new Toast().toast('danger', msg, false, {
          callback: () => {
            window.location.href = '/landing.html'
          }
        })
      }
    } else {
      if (icon) {
        icon.onclick = () => {
          window.open('/models.html', '_blank')
        }
      }
    }

    dashboardBody?.classList.remove('d-none')
  }

  if (isInDashboard) {
    if (res.data?.isPortalAdmin === 0 && !!res.data?.customerId) {
      dashboardBody?.classList.remove('d-none')

      new Toast().toast('danger', '管理者権限がありません。', false, {
        callback: () => {
          window.location.href = '/landing.html'
        }
      })
    }
  }

  if (!isNoAuthPage && isInDashboard) {
    const customerRes = await request('portal/customer', null, true, 'GET')
    // works for dashboard or other pages
    if (customerRes.code === 200 && customerRes.data && customerRes.data.myDeptType === 2) {
      const sidebar = document.querySelector('#sidebarMenu')
      const ordersEl = sidebar?.querySelector('.sidebar-orders')
      const devicesEl = sidebar?.querySelector('.sidebar-devices')

      ordersEl?.classList.add('d-none')
      devicesEl?.classList.add('d-none')
    }
    dashboardBody?.classList.remove('d-none')

    // hide password expired modal for test
    // if (res.data?.isPasswordExpired) {
    //   const passwordExpiredModalEl = document.querySelector('#passwordExpiredModal')
    //   if (passwordExpiredModalEl) {
    //     new mdb.Modal(passwordExpiredModalEl).show()
    //   }
    // }

    // Get notification
    const notificationRes = await request('portal/invitation', null, true, 'GET')
    if (notificationRes.code === 200 && notificationRes.data) {
      document.querySelectorAll('.enterprise-notification').forEach(el => {
        el.classList.remove('d-none')
      })

      localStorage.setItem('SCANAT_NOTIFICATION', JSON.stringify(notificationRes.data))
    }
  }

  beforePage()

  if (location.pathname.includes('rules.html')) {
    window.location.href = '/assets/files/rules.pdf'
    return
  }

  const sidebar = document.querySelector('#sidebarMenu')
  // sidebar
  if (location.pathname.includes('dashboard.html')) {
    const order = sidebar?.querySelector('.sidebar-dashboard')
    order?.classList.add('sidebar-active')

    if (navDashboardEl) {
      navDashboardEl.classList.add('active')
      navDashboardEl.onclick = () => {}
    }
  }

  if (location.pathname.includes('enterprise.html')) {
    const order = sidebar?.querySelector('.sidebar-enterprise')
    order?.classList.add('sidebar-active')

    if (navDashboardEl) {
      navDashboardEl.classList.add('active')
      navDashboardEl.onclick = () => {}
    }
  }

  if (location.pathname.includes('orders.html') || location.pathname.includes('payment.html')) {
    const order = sidebar?.querySelector('.sidebar-orders')
    order?.classList.add('sidebar-active')

    if (navDashboardEl) {
      navDashboardEl.classList.add('active')
      navDashboardEl.onclick = () => {}
    }
  }

  if (location.pathname.includes('devices.html')) {
    const order = sidebar?.querySelector('.sidebar-devices')
    order?.classList.add('sidebar-active')

    if (navDashboardEl) {
      navDashboardEl.classList.add('active')
      navDashboardEl.onclick = () => {}
    }
  }

  // invitation
  if (location.pathname.includes('invitation.html')) {
    const order = sidebar?.querySelector('.sidebar-invitation')
    order?.classList.add('sidebar-active')

    if (navDashboardEl) {
      navDashboardEl.classList.add('active')
      navDashboardEl.onclick = () => {}
    }
  }

  // navbar
  const navbar = document.querySelector('.navbar-nav')
  let currentActiveClass
  switch (true) {
    case location.pathname.includes('fee-plans.html'):
      currentActiveClass = '.nav-fee'
      break
    case location.pathname.includes('introduction.html'):
      currentActiveClass = '.nav-intro'
      break
    case location.pathname.includes('show-case.html'):
      currentActiveClass = '.nav-case'
      break
    case location.pathname.includes('content-tab-5.html'):
    case location.pathname.includes('/articles/'):
    case location.pathname.includes('news.html'):
      currentActiveClass = '.nav-news'
      break
    default:
      currentActiveClass = '.nav-home'
      break
  }
  const currentActiveNav = navbar?.querySelector(currentActiveClass)
  currentActiveNav?.classList.add('active')

  // tooltip
  const downloadEl = document.querySelector('.download')
  if (downloadEl) {
    const tooltip = new mdb.Tooltip(downloadEl)
    tooltip._config.title = 'このアプリはiOSのみ対応しております。'
    tooltip._config.placement = 'bottom'
    tooltip._config.customClass = 'tooltip-download'
  }

  const qrCodeEl = document.querySelector('.qrcode')
  if (qrCodeEl) {
    new mdb.Tooltip(qrCodeEl, {
      title: 'qr code',
      html: true,
      placement: 'right',
      customClass: 'tooltip-qrcode',
      trigger: 'hover',
      template: `<div class="tooltip-qrcode" role="tooltip"><img src=${qrcodeImg} alt='qrcode'  /></div>`,
      container: qrCodeEl
    })
  }

  const urlToken = location.search.split('?token=')?.[1]
  if (urlToken) {
    localStorage.setItem('SCANAT_TOKEN', urlToken)
  }
}

if (['interactive', 'complete'].includes(document.readyState) && !window.isScanatLoaded) {
  handleDomContentLoad()
  document.removeEventListener('DOMContentLoaded', handleDomContentLoad)
} else {
  document.addEventListener('DOMContentLoaded', handleDomContentLoad)
}

const userInfoInterval = () => {
  // set timer to get user info every 1 minute
  setInterval(async () => {
    await request('auth/currentUserInfo')
  }, 30000)
}

export {
  DEV_BASE_URL,
  request,
  formatDateTime,
  formatMoney,
  isMobile,
  genPagination,
  formatEmailDisplay,
  hasWhiteSpace,
  isEmail,
  queryAWSAccessHeader,
  queryDownloadURL,
  isModelKey,
  toDataURL,
  isLocal,
  userInfoInterval,
  isInDashboard,
  checkAccountActive
}
